import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id: 'dashboard',
                title: 'Dashboard',
                translate: '',
                icon: 'dashboard',
                type: 'item',
                url: '/apps/dashboard'
            },
        ]
    },
    {
        id      : 'b2b_administration',
        title   : 'B2B Administration',
        type    : 'group',
        children: [
            {
                id: 'brands',
                title: 'My Brands',
                translate: '',
                type: 'item',
                icon: 'games',
                url: 'apps/b2b-administration/brands'
            },
            {
                id: 'retailers',
                title: 'Retailers',
                translate: '',
                type: 'item',
                icon: 'shop',
                url: 'apps/b2b-administration/retailers'
            },
            {
                id: 'orders',
                title: 'B2B Orders',
                translate: '',
                type: 'item',
                icon: 'receipt',
                url: 'apps/b2b-administration/orders'
            },
            {
                id: 'payment',
                title: 'Payment',
                translate: '',
                type: 'item',
                icon: 'money',
                url: 'apps/b2b-administration/payment'
            },
            {
                id: 'reports',
                title: 'Report',
                translate: '',
                type: 'item',
                icon: 'pie_chart',
                url: 'apps/b2b-administration/report'
            }
        ]
    },
    // {
    //     id: 'b2c_administration',
    //     title: 'B2C Administration',
    //     type: 'group',
    //     children: [
    //         {
    //             id: 'customer-info',
    //             title: 'Customer Information',
    //             translate: '',
    //             type: 'item',
    //             icon: 'person',
    //             url: 'apps/b2c-administration/customer-info'
    //         },
    //         {
    //             id: 'orders',
    //             title: 'B2C Orders',
    //             translate: '',
    //             type: 'item',
    //             icon: 'receipt',
    //             url: 'apps/b2c-administration/orders'
    //         },
    //         {
    //             id: 'transaction-history',
    //             title: 'Transaction History',
    //             translate: '',
    //             type: 'item',
    //             icon: 'receipt',
    //             url: 'apps/b2c-administration/transaction-history'
    //         },
    //         {
    //             id: 'report',
    //             title: 'Reports',
    //             translate: '',
    //             type: 'item',
    //             icon: 'list',
    //             url: 'apps/b2c-administration/report'
    //         }
    //     ]
    // },
    {
        id: 'catalogue',
        title: 'Catalogue',
        type: 'group',
        children: [
            {
                id: 'catalogue-list',
                title: 'Catalogue',
                translate: '',
                type: 'item',
                icon: 'photo',
                url: 'apps/b2b-administration/brands',
            },
            {
                id: 'linesheets',
                title: 'Linesheets',
                translate: '',
                type: 'item',
                icon: 'list',
                url: 'apps/catalogue/linesheets'
            }
        ]
    },
    {
        id: 'management',
        title: 'Management',
        type: 'group',
        children: [
            {
                id: 'connection',
                title: 'Connection',
                translate: '',
                type: 'item',
                icon: 'device_hub',
                url: 'apps/management/connection',
            },
            {
                id: 'partner-management',
                title: 'Partner Management',
                translate: '',
                type: 'item',
                icon: 'photo',
                url: 'apps/management/partner-management',
            },
            {
                id: 'ik-market',
                title: 'IK Market',
                translate: '',
                type: 'item',
                icon: 'store',
                url: 'apps/management/ik-market',
            },
            {
                id: 'ik-scan',
                title: 'iK-Scan',
                translate: '',
                type: 'item',
                icon: 'beenhere',
                url: 'apps/management/ik-scan',
            },
        ]
    }

];
