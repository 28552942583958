import { Component, ViewEncapsulation, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EventEmiterService } from 'app/event.emmiter.service';
import { CustomerService } from '../../../main/apps/customer.service';


import * as moment from 'moment';


@Component({
    selector     : 'quick-panel',
    templateUrl  : './quick-panel.component.html',
    styleUrls    : ['./quick-panel.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class QuickPanelComponent
{
  date = Date.now();
  events: any[];
  notes: any[];
  settings: any;
  notification: any;
  notificationTime: any;
  count:any;

  companyId:any;


  /**
   * Constructor
   */
  constructor(
      private http: HttpClient,
      public customer: CustomerService,
      public EventEmiter: EventEmiterService,

      )
  {

          this.customer.getnotifications();

  }


  ngOnInit() {

      }

      readAllNotifications(){
        this.customer.readAllNotifications();
      }
}
