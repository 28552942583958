import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule, MatIconModule } from '@angular/material';
import { InMemoryWebApiModule } from 'angular-in-memory-web-api';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { SlideshowModule } from 'ng-simple-slideshow';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { FakeDbService } from '../fake-db/fake-db.service';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { DataTablesModule } from 'angular-datatables';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { QuillModule } from 'ngx-quill';
import { CustomToastComponent } from './main/apps/customer.service';

const config: SocketIoConfig = { url: 'https://14022017.intrakraft.com', options: {} };
//  const config: SocketIoConfig = { url: 'http://localhost:3001', options: {} };
//  const config: SocketIoConfig = { url: 'http://192.168.0.165:3001', options: {} };


const appRoutes: Routes = [
    {
        path: 'apps',
        loadChildren: './main/apps/apps.module#AppsModule',
    },
    {
        path: '**',
        redirectTo: 'apps/auth/login'
    }
];



@NgModule({
    declarations: [
        AppComponent,
        CustomToastComponent
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        SocketIoModule.forRoot(config),
        TranslateModule.forRoot(),
        ToastNotificationsModule.forRoot({component: CustomToastComponent}),
        InMemoryWebApiModule.forRoot(FakeDbService, {
            delay: 0,
            passThruUnknownUrl: true
        }),

        // Material moment date module
        MatMomentDateModule,
        QuillModule.forRoot(),
        // Material
        MatButtonModule,
        MatIconModule,


        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        DataTablesModule,
        SlideshowModule
    ],
    bootstrap   : [
        AppComponent
    ],
    entryComponents: [
      CustomToastComponent, // this declaration is required for imperative loading
]
})
export class AppModule
{
}
