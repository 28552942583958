import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventEmiterService {
    private subject = new Subject<any>();

    Ncount:any=0;

    cartCount(cartCount){
      this.subject.next({ text: cartCount });
      console.log(cartCount);
    }

    catalogueDetails(catalogue){
      this.subject.next({ catalogueID: catalogue });
      console.log(catalogue);
    }

    sendMessage(message: string) {
        this.subject.next({ text: message });
        console.log(message);
    }

    clearMessage() {
        this.subject.next();
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
    getCatalogueID(): Observable<any> {
        return this.subject.asObservable();
    }

    notifcationcount(notificationcount){
      this.subject.next({text: notificationcount});
    }
}
